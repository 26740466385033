<template>
  <div>
    <div class="column" style="margin-bottom:0" v-loading="divisors.loading">
      <div class="flex-row align-items-center margin-bottom-10">
        <div class="margin-right-10">因子池</div>
        <el-select v-model="curProdId" size="mini">
          <el-option v-for="(item,index) in productList" :key="index" :value="item.productId" :label="item.productName"></el-option>
        </el-select>
      </div>
      <el-table
        ref="datatable"
        :height="$root.docHeight-225"
        size="medium"
        :data="divisors.list"
      >
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column label="预测产品">
          <template>{{ curProd.productName }}</template>
        </el-table-column>
        <el-table-column label="因子名称" min-width="250">
          <template slot-scope="props">
            <div>
              {{props.row.divisorName}}
              <el-tag type="danger" size="mini" v-if="props.row.isTarget" class="margin-lr-5">目标</el-tag>
              <el-tag type="warning" size="mini" v-if="props.row.isOut" class="margin-lr-5">外生</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="divisorUnit"></el-table-column>
        <el-table-column label="操作" prop="" width="80">
          <template slot-scope="props">
            <div class="flex-row">
              <div class="link-text" @click="getDivisorChart(props.row)">查看因子</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
     <!-- 因子图表弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="divisorData.visible" class="tabs-title-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="divisorData.activeTab">
            <el-tab-pane label="查看因子" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="divisorData.loading">
          <template v-if="divisorData.activeTab=='chart'">
            <div><span>卓创赋值说明：</span>{{divisorData.info.sciReason}}</div>
            <div class="margin-top-5"><span>滨化赋值说明：</span>{{divisorData.info.befarReason}}</div>
            <Chart :option="divisorChart" class="flex-auto"></Chart>
          </template>
          <template v-if="divisorData.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="divisorData.info.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{ props.row.dateCycleShow }}</template>
              </el-table-column>
              <el-table-column v-for="(item,index) in divisorData.info.items" :key="index" :label="item.name">
                <template slot-scope="props">{{ props.row[item.id] | numFormat(2,'-') }}</template>
              </el-table-column>
             
            </el-table>
          </template>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import Chart from "@/components/Chart";
export default {
  name: 'modelManageDivisors',
  components:{
    Chart,
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    },
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data (){
    return{
      curProdId:'',
      divisors: {
        loading:false,
        list:[]
      },
      divisorActive:{},
      divisorData:{//因子图表详情
        loading:false,
        visible:false,
        activeTab:'chart',
        info:{}
      },
      
    }
  },
  computed:{
    curProd(){
      if(this.curProdId){
        return this.productList.find(v=>{return v.productId==this.curProdId})
      }else{
        return {}
      }
    },
    //因子详情走势图
    divisorChart(){
      let chartData=this.divisorData.info
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=this.divisorActive.unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dateCycleShow})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:this.divisorActive.divisorUnit,
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='hisValue'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='hisValue'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['hisValue'])||!params.some(v=>v.seriesId=='hisValue')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    arrReverse(){
      return (res)=>{
        let newArr= JSON.parse(JSON.stringify(res||[])) 
        return newArr.reverse()
      }
    },
    getDataListener(){
      let modelFreq=this.modelFreq,
          curProdId=this.curProdId
      return{
        modelFreq,
        curProdId
      }
    }
  },
  created(){
    this.curProdId=(this.productList[0]||{}).productId||""
  },
  mounted(){

  },
  methods:{
    
    getData(){
      if(this.curProdId){
        this.getList()
      }
    },
    //获取列表信息
    getList(){
      this.divisors.loading=true
      let request=this.$instance.get('/model/getDivisorList',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           targetId:this.curProd.targetId,
          },
      })
      request.then(res=>{
        this.divisors.loading=false
        if(res.data.code==0){
          this.divisors.list=res.data.info
        }else{
          this.divisors.list=[]
        }
      })
      request.catch(()=>{
        this.divisors.loading=false
        this.divisors.list=[]
      })
      return request
    },
    //获取因子走势图详情
    getDivisorChart(divisor){
      this.divisorActive=divisor
      this.divisorData={
        loading:true,
        visible:true,
        activeTab:'chart',
        info:{}
      }
      this.$instance.get('/assignment/getDivisorDetail',{
          params: {
            ...this.$store.state.basicParams,
            productId:this.curProdId,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
          },
      })
      .then(res=>{
        this.divisorData.loading=false;
        if(res.data.code==0){
          this.divisorData.info=res.data.info;
        }else{
          this.divisorData.info={}
        }
      })
      .catch(()=> {
        this.divisorData.loading=false;
        this.divisorData.info={}
      }); 
    },
    
  },
  
  watch: {
    'productList':{
      handler:function(){
        this.curProdId=(this.productList[0]||{}).productId||""
      },
      deep:true,
    },
    "getDataListener" : {
      handler:function(){
        this.getData()
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style lang="scss">

  .tabs-title-dialog{
    .el-tabs__header{margin: 0;}
    .el-dialog__body{
      padding-top:0px
    }
  }

</style>